$(document).ready(function(){

	$(".table").tablesorter();
	var $rows = $('table.searchable tbody tr');
	$('.search-control').keyup(function() {
	    
	    var val = '^(?=.*\\b' + $.trim($(this).val()).split(/\s+/).join('\\b)(?=.*\\b') + ').*$',
	        reg = RegExp(val, 'i'),
	        text;
	    
	    $rows.show().filter(function() {
	        text = $(this).text().replace(/\s+/g, ' ');
	        return !reg.test(text);
	    }).hide();
	});


	$('a.filter').click(function(e) {
	    e.preventDefault();
	    var val = '^(?=.*\\b' + $.trim($(this).data('filterby')).split(/\s+/).join('\\b)(?=.*\\b') + ').*$',
	        reg = RegExp(val, 'i'),
	        text;
	    //var filter = $(this).prop('href').split(':')
	    //console.log($(this),val)
	    if($(this).hasClass('filter-active')){
	    	reg = RegExp('', 'i');
	    	$(this).removeClass('filter-active is-success');
	    }else{
	    	$(this).addClass('filter-active is-success');
	    }

	    $rows.show().filter(function() {
	        text = $(this).text().replace(/\s+/g, ' ');
	        return !reg.test(text);
	    }).hide();
	});

	$SITEROOT = '/';//http://auk.local/auk/apps/reports/20151024/public';//$('link[ref="canonical"]').prop('href');
	//console.log($SITEROOT);
	$("form.toValidate").validate({
	  invalidHandler: function(event, validator) {
	    // 'this' refers to the form
	    var errors = validator.numberOfInvalids();
	    if (errors) {
	      var message = errors == 1
	        ? 'You missed 1 field. It has been highlighted'
	        : 'You missed ' + errors + ' fields. They have been highlighted';
	      $("div.error span").html(message);
	      $("div.error").show();
	    } else {
	      $("div.error").hide();
	    }
	  },
	  submitHandler: function() { alert("Submitted!"); }
	});

	// set up jQuery with the CSRF token, or else post routes will fail
	

	// handlers
	function onGetClick(event)
	{
		// we're not passing any data with the get route, though you can if you want
		$.get($SITEROOT+'/ajax/get', onSuccess);
	}

	function onPostClick(event)
	{
		// we're passing data with the post route, as this is more normal
		console.log(event);
		$.post($SITEROOT+'/process', $("form.toValidate").serialize(), onSuccess);
	}	

	function onClickPreview(event)
	{
		// we're passing data with the post route, as this is more normal
		//console.log(event);
		$.post('/emails/preview', $("form.prValidate").serialize(), onPreviewSuccess);
	}

	function onClickSaveDraft(event)
	{
		// we're passing data with the post route, as this is more normal
		//console.log(event);

		$.post('/emails', $("form.prValidate").serialize()+'&status=draft', onSuccess);
	}

    function sendFile(file)
    {
        var formData = new FormData();
        formData.append("upload", file);


           $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() } });

            var ajax = $.ajax({
                type: 'post',
                url: $SITEROOT+'/process',
                data: formData,
                contentType: false,
                processData: false
            })
            .done(function() {
                alert("success");
            })
            .fail(function() {
                alert("fail");
            });
	}

	function onSuccess(data, status, xhr)
	{
		// with our success handler, we're just logging the data...
		//console.log(data, status, xhr);

		// but you can do something with it if you like - the JSON is deserialised into an object
		//console.log(String(data).toUpperCase());
	}

	function onPreviewSuccess(data, status, xhr)
	{
		// with our success handler, we're just logging the data...
		//console.log(data, status, xhr);
		$('#previewMailModal .modal-body #modal-frame').contents().find('body').empty().html(data.data);
		// but you can do something with it if you like - the JSON is deserialised into an object
		//console.log(String(data).toUpperCase());
	}

	$('.btn-action').on('click',function(){
		//console.log($(this).data().recordid);
		//console.log($(this).hasClass('action-remove'));
		if($(this).hasClass('action-remove')){
			$('#removeClientModal').find('form').prop('action',$(this).data().recordid);
			console.log($('#removeClientModal').find('form').prop('action'));
		}

		
	});


	// Listen for click on toggle checkbox
	$('a#check_all').on('click',function(event) {
		var check = true;
		//console.log($(this).hasClass('checked'));
	    if($(this).hasClass('checked')) {
	    	check = false;
	    	$(this).removeClass('checked');
	    	this.text = 'Check All';
	    }else{
	    	$(this).addClass('checked');
	    	this.text = 'UnCheck All';
	    }
        // Iterate each checkbox
        $(':checkbox').each(function() {
            this.checked = check;                        
        });
	});

	// jQuery method:
	$('.actions_control').find('select[name="action"]').on('change',function(e){
	  	var selectionValue = this.value;
	  	var searchIDs = $(".table input:checkbox:checked").map(function(){
	      	return $(this).val();
	    }).get();
	  $('input[name="user_ids"]').val('');
	  if(selectionValue == 'tags'){
	  	$('input[name="user_ids"]').val(searchIDs.join(','));
	  	$('#addTagsModal').modal('show');
	  }else if(selectionValue == 'delete'){
	  	$('input[name="user_ids"]').val(searchIDs.join(','));
	  	$('#removeRecordsModal').modal('show');
	  }
	});
	$('a#emailPreview').on('click', onClickPreview);
	$('#saveDraft').on('click', onClickSaveDraft);

});

